<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">违章汇总</div>
				<div class="header-button">
					<el-button
						@click="onExport"
						v-permission="'pc/car-center/Illegal-count/export'"
						:loading="buttonLoading"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="setting" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
					@sort-change="onSortChange"
				>
					<template #untreatedNum="{ value, row }">
						<span class="click" @click="onClick(row)">{{ value }}</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
	</div>
</template>

<script name="ViolationManagement">
import { ref, defineComponent, inject, getCurrentInstance, onActivated, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { setting as s, columns } from './config'
import { handleExport } from '@/utils/util'

import { InitTable, useFetch, useState, useFilter } from '@/utils/hooks.js'
import { companySelect, untreatedSummary, exportUntreatedSummary } from '@/api/vehicle'

export default defineComponent({
	name: 'ViolationManagement',
	setup() {
		const formRef = ref(null)
		const setting = ref(s)
		const $message = inject('$message')
		const router = useRouter()
		const params = {
			page: { current: 1, size: 20 },
		}
		const [buttonLoading, setLoading] = useState(false)

		const table = new InitTable(untreatedSummary, params)
		const { fetchData } = useFetch()
		const methods = {
			onChangeFilter(params) {
				table.onChangeFilter(params)
			},
			onCreate() {
				formRef.value.open()
			},

			async onExport() {
				setLoading(true)
				try {
					const res = await exportUntreatedSummary(table.params)
					if (res) handleExport(res, '违章汇总')
				} catch (err) {
					$message.error(err.message)
				} finally {
					setLoading(false)
				}
			},
			onClick(row) {
				console.log('row', row)
				const { carNumber } = row
				router.push({ path: '/violation-management', query: { keyword: carNumber, status: 1 } })
			},
			findItem(key) {
				return setting.value.filters.find(item => item.attr === key)
			},
			async fetchFilters() {
				const [comRes] = await Promise.all([
					useFilter(() => companySelect({ isPermission: true }), {
						name: 'companyName',
						code: 'companyId',
					}),
				])
				if (!comRes.err) {
					const target = methods.findItem('companyId')
					target.options = comRes.data
				}
			},
			onSortChange(row) {
				console.log('row', row)
				const { order, prop } = row
				if (prop === 'untreatedFine') {
					table.params.isUntreatedFineDesc = order === 'descending'
					table.params.isUntreatedScoreDesc = null
				}
				if (prop === 'untreatedScore') {
					table.params.isUntreatedScoreDesc = order === 'descending'
					table.params.isUntreatedFineDesc = null
				}
				table.fetchList()
			},
		}

		function init() {
			const ins = getCurrentInstance()
			const route = useRoute()
			const query = route.query
			if (Object.keys(query).length > 0) {
				params.keyword = query?.keyword || ''
				params.companyId = query?.companyIds?.split(',') || []
				params.violateDate = null
				ins.refs.filterRef && ins.refs.filterRef.manualSetParams(params)
			} else {
				ins.refs.filterRef && ins.refs.filterRef.manualSetParams(params)
			}
			methods.fetchFilters()
		}
		onActivated(() => {
			init()
		})
		onMounted(() => {
			init()
		})

		return {
			setting,
			columns,
			...table.res,
			buttonLoading,
			...methods,
		}
	},
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
.click {
	cursor: pointer;
	color: #409eff;
}
</style>
