export const columns = [
	{
		prop: 'carModel',
		label: '车型名称',
		width: '280px',
	},
	{
		prop: 'carNum',
		label: '自编号',
		width: '120px',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '160px',
	},
	{
		prop: 'untreatedNum',
		label: '未处理笔数',
		width: '120px',
	},
	{
		prop: 'untreatedScore',
		label: '未处理分数',
		width: '120px',
		sortable: 'custom',
	},
	{
		prop: 'untreatedFine',
		label: '未处理金额',
		width: '120px',
		sortable: 'custom',
	},
	{
		prop: 'lastTime',
		label: '更新时间',
	},
	{
		prop: 'companyName',
		label: '所属公司',
		width: '240px',
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		width: '120px',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			attr: 'keyword',
			label: '车辆信息',
			type: 'search',
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			clearable: true,
			width: 358,
		},
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyId',
			type: 'multi-select',
			width: 320,
			options: [],
		},
	],
}
